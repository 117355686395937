<template>
  <div>
    <div class="flix-form-group">
      <h3 class="flix-html-h3"><flixIcon id="envelope" /> {{ $t('reminder.email') }} {{ $tc('message.reminder', 2) }}</h3>
      <span class="flix-text-danger" v-if="blacklist.email" v-html="blacklist.email" />
      <span class="flix-text-success" v-else><flixIcon id="ok" /> {{ $t('message.active') }}</span>
      <a href="#" style="margin-left: 8px" class="flix-btn flix-btn-default flix-btn-xs" @click.prevent="editBlacklist(blacklist.email, data.email, 'email')">{{ $t('message.edit', { name: '' }) }}</a>
    </div>
    <div class="flix-form-group" v-if="data.tel">
      <h3 class="flix-html-h3"><flixIcon id="iphone" /> {{ $t('reminder.tel') }} {{ $tc('message.reminder', 2) }}</h3>
      <span class="flix-text-danger" v-if="blacklist.sms" v-html="blacklist.sms" />
      <span class="flix-text-success" v-else><flixIcon id="ok" /> {{ $t('message.active') }}</span>
      <a href="#" style="margin-left: 8px" class="flix-btn flix-btn-default flix-btn-xs" @click.prevent="editBlacklist(blacklist.sms, data.tel, 'sms')">{{ $t('message.edit', { name: '' }) }}</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
      updateKey: 0,
      blacklist: {
        email: false,
        sms: false
      },
      user: false
    }
  },
  computed: {

  },
  mounted () {
    this.getData()
  },
  methods: {
    editBlacklist (active, to, type) {
      if (type === 'email') {
        to = this.data.email
        active = this.blacklist.email.trim()
      } else {
        to = this.data.tel
        active = this.blacklist.sms.trim()
      }

      if (active) {
        this.changeBlacklist({
          user: this.user,
          email: to
        }, function (ret) { this.getData() }.bind(this))

        return false
      }

      if (!active) {
        this.changeBlacklist({
          user: this.user,
          email: to,
          status: true
        }, function (ret) { this.getData() }.bind(this))

        return false
      }
    },
    getData () {
      this.user = this.data.booking_id.split('-')
      this.user = this.user[0] * 1

      this.checkBlacklist({
        user: this.user,
        to: this.data.email,
        group: '*'
      }, function (ret) { this.blacklist.email = ret.data }.bind(this))

      this.checkBlacklist({
        user: this.user,
        to: this.data.tel,
        group: '*'
      }, function (ret) { this.blacklist.sms = ret.data }.bind(this))
    },
    changeBlacklist (data, callback) {
      this.$flix_post({
        url: 'blacklist/set',
        data: data,
        callback: function (ret) { callback(ret); this.updateKey = new Date().getTime() }.bind(this)
      })
    },
    checkBlacklist (data, callback) {
      if (!data.to.trim()) {
        return false
      }
      this.$flix_post({
        url: 'blacklist/check',
        data: data,
        callback: function (ret) { callback(ret); this.updateKey = new Date().getTime() }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
